<template>
  <v-container>
    <v-row
      v-permission="{
        roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
        operator: '==='
      }"
    >
      <v-col class="text-right">
        <BaseButton
          id="btn-create"
          name="btn-create"
          title="Adicionar documento"
          color="secondary"
          @click="create"
        />
      </v-col>
    </v-row>

    <BaseTableList title="Listagem de documentos">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.attachmentDate`]="{ item }">
            {{ formatDate(item.attachmentDate) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="download(item)"
                >
                  fa-solid fa-download
                </v-icon>
              </template>
              <span>Baixar arquivo</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  small
                  @click="remove(item)"
                  v-permission="{
                    roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                    operator: '==='
                  }"
                >
                  fa-regular fa-trash-can
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton @click="back" outlined color="primary" title="Voltar" />
      </v-col>
    </v-row>

    <DialogDocuments
      v-if="dialog"
      :dialog="dialog"
      :title="dialogTitle"
      :document="$route.params.cnpj"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { formatDate } from '@/helpers/formatting';
import { downloadFile } from '@/helpers/download';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { InsuranceCompanyService } from '@/services/api/insuranceCompany';

export default {
  mixins: [rulesMixin],

  components: {
    DialogDocuments: () => import('./dialogs/dialog-documents')
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    dialog: false,
    dialogTitle: '',
    options: {},
    document: {
      documentType: null,
      documentPath: null,
      file: null,
      name: null,
      startCampaignDate: null,
      expirationCampaignDate: null
    },
    headers: [
      { text: 'Documento', value: 'documentType' },
      { text: 'Data de anexo', value: 'attachmentDate' },
      { text: 'Nome do arquivo', value: 'name' },
      { text: '', value: 'action' }
    ]
  }),

  methods: {
    formatDate,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status, data } = await insuranceCompanyService.getDocuments({
          page,
          take: itemsPerPage,
          insuranceCompanyDocument: this.$route.params.cnpj
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    create() {
      this.dialog = !this.dialog;
    },

    back() {
      this.$emit('back');
    },

    closeModal(search) {
      this.dialog = false;

      if (search) {
        this.search();
      }
    },

    async remove(item) {
      try {
        const result = await confirmMessage(
          'Atenção!',
          'Deseja realmente excluir o registro selecionado?'
        );

        if (result) {
          const insuranceCompanyService = new InsuranceCompanyService();
          const { status } = await insuranceCompanyService.deleteDocument({
            insuranceCompanyDocument: this.$route.params.cnpj,
            ...item
          });

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async download(item) {
      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status, data } = await insuranceCompanyService.downloadDocument(
          {
            insuranceCompanyDocument: this.$route.params.cnpj,
            ...item
          }
        );

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
