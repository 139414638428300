var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{directives:[{name:"permission",rawName:"v-permission",value:({
      roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
      operator: '==='
    }),expression:"{\n      roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n      operator: '==='\n    }"}]},[_c('v-col',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"id":"btn-create","name":"btn-create","title":"Adicionar documento","color":"secondary"},on:{"click":_vm.create}})],1)],1),_c('BaseTableList',{attrs:{"title":"Listagem de documentos"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.attachmentDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.attachmentDate))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.download(item)}}},on),[_vm._v(" fa-solid fa-download ")])]}}],null,true)},[_c('span',[_vm._v("Baixar arquivo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                  operator: '==='
                }),expression:"{\n                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n                  operator: '==='\n                }"}],attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.remove(item)}}},on),[_vm._v(" fa-regular fa-trash-can ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}],null,true)})]},proxy:true}])}),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"outlined":"","color":"primary","title":"Voltar"},on:{"click":_vm.back}})],1)],1),(_vm.dialog)?_c('DialogDocuments',{attrs:{"dialog":_vm.dialog,"title":_vm.dialogTitle,"document":_vm.$route.params.cnpj},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }